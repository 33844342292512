import {
  Box,
  Button,
  chakra,
  FormLabel,
  Input,
  LightMode,
  Stack,
  useColorModeValue,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import * as React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default function InvitationForm() {
  const [email, setEmail] = React.useState<string | undefined>('')
  const [loading, setLoading] = React.useState(false)
  const toast = useToast()
  const onSubmit = React.useCallback(
    (e) => {
      e.preventDefault()
      setLoading(true)
      addToMailchimp(email, {})
        .then((data: any) => {
          console.log('data ', data)
          if (data && data.result === 'error') {
            const isNotValidated =
              data.msg.indexOf('The email you entered is not valid') >= 0
            toast({
              title: 'Submit a bug request.',
              description: isNotValidated
                ? 'The email you entered is not valid'
                : 'You have already submitted your request',
              position: 'bottom',
              status: isNotValidated ? 'error' : 'warning',
            })
          } else {
            toast({
              title: 'Your request has been sent.',
              description: 'Our ream will get in touch shortly.',
              position: 'bottom',
              status: 'success',
            })
          }
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    [email]
  )
  return (
    <chakra.form onSubmit={onSubmit}>
      <LightMode>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          align={{ md: 'flex-end' }}
        >
          <Box flex="1">
            <FormLabel htmlFor="email" srOnly>
              Enter work email
            </FormLabel>
            <Input
              disabled={loading}
              id="email"
              name="email"
              type="email"
              size="lg"
              fontSize="md"
              value={email}
              onChange={(value) => setEmail(value.target.value || '')}
              bg="white"
              _placeholder={{ color: 'gray.400' }}
              color="gray.900"
              placeholder="Your work email address"
              focusBorderColor="blue.200"
            />
          </Box>
          <Button
            disabled={loading}
            size="lg"
            type="submit"
            leftIcon={loading ? <Spinner size="sm" /> : undefined}
            px="10"
            fontSize="md"
            backgroundColor={useColorModeValue('brand.500', 'brand.500')}
            color="gray.50"
          >
            Request Demo
          </Button>
        </Stack>
      </LightMode>
    </chakra.form>
  )
}
